<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <h1>Czym jest Multi!Apka – Karta Stałego Klienta?</h1>
          <hr style="height: 2px; opacity: 1; margin-top:12px; margin-bottom:24px;">
          <p class="mt-4">Proste zasady i wszystkie rabaty oraz akcje specjalne zawsze pod ręką, w Twoim telefonie. Już teraz ściągnij za darmo Multi!Apkę z elektroniczną Kartą Stałego Klienta i korzystaj z szerokiej gamy rabatów przez cały rok. Dodatkowo śledź wydarzenia specjalne i zdobywaj atrakcyjne nagrody! Z Multi!Apką zakupy staną się jeszcze większą przyjemnością!</p>
        </div>
      </div>
      <div class="row mt-5">
        <a :href="m.url" v-for="(m, index) in malls" :key="index" class="col-6 col-lg-4 p-3">
          <img :src="m.img" :alt="m.name" class="w-100">
        </a>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
      malls: [
        {
          name: 'Poznań Plaza',
          img: require('@/assets/malls/poznan.svg'),
          url: 'https://poznanplaza.multiapka.pl/'
        },
        {
          name: 'Lublin Plaza',
          img: require('@/assets/malls/lublin.svg'),
          url: 'https://lublinplaza.multiapka.pl/'
        },
        {
          name: 'Sadyba Best Mall',
          img: require('@/assets/malls/sadyba.svg'),
          url: 'https://sadyba.multiapka.pl/'
        },
        {
          name: 'Rybnik Plaza',
          img: require('@/assets/malls/rybnik.svg'),
          url: 'https://rybnikplaza.multiapka.pl/'
        },
        {
          name: 'Ruda Śląska Plaza',
          img: require('@/assets/malls/ruda.svg'),
          url: 'https://rudaslaskaplaza.multiapka.pl/'
        },
        {
          name: 'Sosnowiec Plaza',
          img: require('@/assets/malls/sosonowiec.svg'),
          url: 'https://plazasosnowiec.multiapka.pl/'
        }
      ],
    }
  },
}
</script>

<style lang="scss">
@import "~bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#app{
  font-family: Barlow, sans-serif;
  overflow: hidden;
}
main {
  color: white;
  background-image: url("./assets/bg_new.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-top: 64px;
  padding-bottom: 80px;
}
h1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  letter-spacing: 0.2px;
}
p {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.1px;
}
</style>
